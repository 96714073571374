<template>
  <div class="my-6 md:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft />
    <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pr-16 lg:pr-32">
      <SocialLoginSection
        :showOtp="showOtp"
      />
    </div>
    <DataserviceLogo />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import SocialLoginSection from "../components/socialloginverif/SocialLoginSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue"

import { getEmailSocialRegistration } from "../services/authenticationService";

export default {
  components: {
    SectionLeft,
    SocialLoginSection,
    DataserviceLogo
  },
  data() {
    return {
      showOtp: false,
     /*  email_address: "",
      provider: "",
      errorGlobal: [], */
    };
  },
  async created() {
   

    /* this.errorPhone.push(
        "A user already exists with this email address and phone number"
      ); */

    this.showOtp = this.$route.params.data;
    // let routeParam = this.$route.params.data;

    // if (routeParam) {
    //   this.activeTab = routeParam;
    // } else {
    //   this.activeTab = 0;
    // }
    document.addEventListener("beforeunload", this.destroyTabParam);
  },
};
</script>
