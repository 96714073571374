<template>
  <div class="flex flex-col justify-center items-center">
    <SignupHeader
      :step="$t('global_step_02_03')"
      :title="$t('social_login_verification')"
      back="primaryregistration"
    />
    <div class="w-9/12 mt-10">
      <img
        class="h-28 mb-12 mx-auto md:mx-0"
        src="@/assets/images/phone-icon-cl.png"
        alt="mail-icon-cl"
      />
    </div>
    <SocialLoginForm />
  </div>
</template>

<script>
import SignupHeader from "../primaryregistration/SignupHeader.vue";
import SocialLoginForm from "../socialloginverif/SocialLoginForm.vue";

export default {
  props: ["showOtp", "email_address", "provider"],
  components: {
    SignupHeader,
    SocialLoginForm,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
